<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-04-10 14:56:13
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-05-01 11:18:11
 * @FilePath: \envision-webe:\sirun\performance-appraisal\src\components\KPIRating.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="performance-rating" class="div-body active"
       style="padding-top: 80px; padding-left: 20px; padding-right: 20px">
    <div class="layui-form">
      <h2>绩效评分</h2>
      <form id="performanceRatingForm" class="layui-form">
        <div class="layui-form-item">
          <label class="layui-form-label" style="width: 120px">选择考评人:</label>
          <div class="layui-input-block">
            <a-select placeholder="请选择考评人" @change="getTable" v-model="currentAppraiser" allow-clear style="width: 60%">
              <a-select-option v-for="item in appraiserArray" :key="item.userid" :value="item.userid"
                               :style="{ color: item.issubmit === 1 ? 'blue' : 'red' }">{{ item.username
                }}{{
                  item.issubmit === 1 ? "(未提交)" : "(已提交)"
                }}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="layui-form-item">
          <label class="layui-form-label" style="width: 120px">选择考评月:</label>
          <div class="layui-input-block">
            <a-select v-model="currentMonth" placeholder="选择考评月" allow-clear @change="getMonthTable" style="width: 60%">
              <a-select-option
                  v-for="month in evMonths"
                  :key="month.value"
                  :value="month.value"
              >
                {{ month.text }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <table class="layui-table">
          <colgroup>
            <col width="150" />
            <col width="150" />
            <col />
          </colgroup>

          <thead>
          <tr>
            <th>考核周期</th>
            <th>考核得分</th>
          </tr>
          </thead>
          <tbody id="user-performance-table-body">
          <tr v-for="(row, index) in appraiser" :key="index">
            <td>{{ row.assessment_period }}</td>
            <td>{{ row.score }}</td>
          </tr>
          </tbody>
        </table>
        <table id="assessorPerformanceTable" class="layui-table">
          <colgroup>
            <col width="150" />
            <col width="150" />
            <col />
          </colgroup>
          <thead>
          <tr>
            <th style="width: 10%">指标类别</th>
            <th style="width: 30%">指标名称</th>
            <th style="width: 30%">计算/评分规则</th>
            <th style="width: 10%">考核分数</th>
            <th style="width: 20%">考核得分</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in rows" :key="index">
            <td>
              {{ row.category }}
            </td>
            <td>
              <a-textarea :disabled="!isRating" v-model="row.description" :rows="4" />
            </td>
            <td>
              <a-textarea :disabled="!isRating" v-model="row.rule" :rows="4" />
            </td>
            <td>
              {{ row.examine_score }}
            </td>
            <td>
              <a-input-number style="width: 100%" v-model="row.score" :disabled="!isRating" placeholder="考核得分"
                              :min="0" :max="120" />
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="4" style="text-align: right">合计：</td>
            <td id="totalScore" style="text-align: left">
              {{ totalPoints }}
            </td>
          </tr>
          </tfoot>
        </table>

        <div class="layui-row">
          <div class="layui-col-sm2 layui-col-md-offset10">
            <a-space>
              <a-button v-if="isTrun" @click="cancel" class="my-per-button">
                驳回
              </a-button>
              <a-button v-else type="primary" disabled class="my-button-disabled">
                驳回
              </a-button>
              <a-button v-if="isRating" @click="submitRating" class="my-button">
                评分
              </a-button>
              <a-button v-else type="primary" disabled class="my-button-disabled">
                评分
              </a-button>
            </a-space>
          </div>
        </div>
      </form>
    </div>

    <div id="rating-manage" class="div-body" style="padding-top: 80px; padding-left: 20px; padding-right: 20px">
      <div class="layui-form">
        <h2>绩效管理</h2>
        <form id="ratingManageForm" class="layui-form"></form>
      </div>
    </div>
  </div>
</template>

<script>
import {baseUrl} from '@/assets/common.js'
import axios from "axios";
const CORP_ID = "ding22edd3ac4f8e7fa8a1320dcb25e91351";
const USER_INFO_URL = "https://cim.dingding.sirun.net/get_dingtalk_user_info";
import * as dd from "dingtalk-jsapi";
export default {
  props: {
    globalAccessToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //是否已经评分过
      baseUrl,
      isRating: false,
      //是否可驳回
      isTrun: false,
      name: "",
      //考评人
      appraiserArray: [],
      //当前考核人
      currentAppraiser: undefined,
      appraiser: [],
      rows: [],
      //考评月
      evMonths: [],
      //当前考核月
      currentMonth: undefined,
    };
  },
  computed: {
    totalPoints: function () {
      if (this.rows && this.rows.length === 0) {
        // 如果 this.rows 不是数组或者为空数组，则返回默认值
        return 0; // 返回默认值
      }
      // 使用 reduce 方法计算所有行的得分总和
      return this.rows.reduce((acc, row) => {
        // 将每行的得分加到累加器中
        return acc + parseFloat(row.score || 0); // 使用 parseFloat 转换为数字，防止出现字符串拼接
      }, 0); // 初始值为 0
    },
  },
  watch: {
    globalAccessToken: {
      handler(newVal) {
        if (newVal) {
          this.init();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.globalAccessToken !== "") {
      this.init();
    }
  },
  methods: {
    //手动刷新
    resetRating() {
      this.currentAppraiser = undefined;
      this.appraiser = [];
      this.rows = [];
    },
    async init() {
      await this.initializeMonths();
      dd.getAuthCode({
        corpId: CORP_ID,
        success: async (res) => {
          await this.getName(res.code);
        },
        fail: (err) => {
          console.error("getAuthCode 失败:", err);
        },
        complete: () => { },
      });
    },
    async getName(code) {
      const url = `${USER_INFO_URL}?access_token=${this.globalAccessToken}&user_code=${code}`;
      try {
        const response = await axios.get(url);
        const data = response.data;
        if (response.status !== 200) {
          throw new Error(
              `获取免登录用户数据失败: ${data.message || response.status}`
          );
        }
        const { name } = data.result;
        this.name = name;
        this.fetchEmployees(name);
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    async fetchEmployees(name) {
      try {
        const response = await axios.get(
            this.baseUrl +
            `user_info_by_assessor/${name}?yearmonth=${this.currentMonth}`
        );
        if (response.status !== 200) {
          throw new Error("网络异常4");
        }
        const data = response.data;
        this.appraiserArray = data;
      } catch (error) {
        console.error("未能获取员工列表:", error);
      }
    },
    initializeMonths() {
      const currentDate = new Date();
      const day = currentDate.getDate();
      const currentYear = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      let nextMonth = month === 12 ? 1 : month + 1;
      let year = currentYear;
      let nextYear = currentYear;

      // 如果是1-3号，需要显示上个月和当前月
      if (day < 4) {
        month = month - 1;
        nextMonth = month + 1;

        // 处理年初情况（当前是1月）
        if (month === 0) {
          month = 12;
          year = currentYear - 1;
          nextMonth = 1;
          nextYear = currentYear;
        }
      }
      // 4号到月末，显示当前月和下个月
      else {
        // 处理年末情况（当前是12月）
        if (month === 12) {
          nextMonth = 1;
          nextYear = currentYear + 1;
        }
      }

      const formattedMonth = month < 10 ? "0" + month : "" + month;
      const formattedNextMonth = nextMonth < 10 ? "0" + nextMonth : "" + nextMonth;

      this.currentMonth = `${year}年${formattedMonth}月`;
      this.evMonths = [
        {
          text: `${year}年${formattedMonth}月`,
          value: `${year}年${formattedMonth}月`,
        },
        {
          text: `${nextYear}年${formattedNextMonth}月`,
          value: `${nextYear}年${formattedNextMonth}月`,
        }
      ];
    },
    isDating() {
      const index = this.evMonths.findIndex(item => item.value === this.currentMonth)
      if (index === 0) {
        this.isRating = true;
        this.isTrun = false;
      } else {
        this.isRating = false;
        this.isTrun = true;
      }
    },
    async getMonthTable() {
      this.isDating();
      await this.updateAppraisers();
      if (this.currentAppraiser && this.currentMonth) {
        this.fetchAndFillpingPerformanceTable(
            this.currentAppraiser,
            this.currentMonth
        );
      }
    },
    getTable() {
      if (this.currentAppraiser) {
        this.getAssessmentCycle(this.currentAppraiser);
      }
      if (this.currentMonth) {
        this.isDating();
      }
      if (this.currentAppraiser && this.currentMonth) {
        this.fetchAndFillpingPerformanceTable(
            this.currentAppraiser,
            this.currentMonth
        );
      }
    },
    async updateAppraisers() {
      try {
        const response = await axios.get(
            this.baseUrl +
            `user_info_by_assessor/${this.name}?yearmonth=${this.currentMonth}`
        );
        if (response.status !== 200) {
          throw new Error("网络异常4");
        }
        const data = response.data;
        this.appraiserArray = data;
        const person = this.currentAppraiser;
        this.currentAppraiser = undefined;
        this.currentAppraiser = person;
      } catch (error) {
        console.error("未能获取员工列表:", error);
      }
    },
    // 获取周期信息
    async getAssessmentCycle(userid) {
      try {
        const res = await axios.get(this.baseUrl + `performance_status/${userid}`);
        if (res.status !== 200) {
          throw new Error("网络异常3");
        }
        const data1 = res.data;
        console.log("fetchAndFillpingPerformanceTable", data1);
        if (!data1.message) {
          this.appraiser = data1;
        } else {
          this.appraiser = [];
        }
        console.log("this.appraiser", this.appraiser);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async fetchAndFillpingPerformanceTable(userid, assessmentPeriod) {
      try {
        const url =
            this.baseUrl +
            `get_performance_status_data?userid=${userid}&assessment_period=${assessmentPeriod}`;
        const response = await axios.get(url);
        if (response.status !== 200) {
          throw new Error("网络异常4");
        }
        const data = response.data;
        console.log("get_performance_status_data", data);
        if (!data.message) {
          this.rows = data;
          console.log('this.isRating', this.isRating)
          console.log('this.isTrun', this.isRating)
          this.rows.forEach((item) => {
            if (item.score !== "") {
              this.isTrun = false
              this.isRating = false
              return
            }
          });
        } else {
          this.rows = [];
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    cancel() {
      const that = this;
      console.log("驳回考核");
      if (this.rows.length > 0) {
        axios
            .post(this.baseUrl + "cancel_rating_data", this.rows)
            .then(() => {
              this.$message.success("绩效驳回成功！");
              setTimeout(() => {
                that.resetRating();
              }, 1000);
            })
            .catch((error) => {
              console.error("绩效驳回失败:", error);
            });
      } else if (this.currentAppraiser && this.currentMonth) {
        this.$message.info("该员工当前月无绩效考核！", 2);
      } else {
        this.$message.info("请选择考评人和考评月！", 2);
      }
    },
    submitRating() {
      const that = this;
      console.log("this.totalPoints", this.totalPoints);
      console.log("this.totalPoints > 120", this.totalPoints > 120);
      if (this.rows.length > 0) {
        if (this.totalPoints > 120) {
          this.$message.error("总分不能大于120，请检查评分数据！", 2);
        } else if (this.totalPoints <= 50) {
          this.$message.error("总分太低，绩效评分应>50", 2);
        } else {
          if (!this.validateData(this.rows)) {
            this.$message.info("请填写加分项原因", 2);
            return;
          }
          axios
              .post(this.baseUrl + "save_rating_data", this.rows, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then(() => {
                this.$message.success("评分数据保存成功！", 2);
                setTimeout(() => {
                  that.resetRating();
                }, 1000);
              })
              .catch((error) => {
                console.error("数据保存失败:", error);
              });
        }
      } else if (this.currentAppraiser && this.currentMonth) {
        this.$message.info("该员工当前月无绩效考核！", 2);
      } else {
        this.$message.info("请选择考评人和考评月！", 2);
      }
    },
    validateData(data) {
      for (let item of data) {
        // 只对 category 为 "加分项" 的情况进行检查
        if (item.category === "加分项") {
          // 检查score是否为空
          if (item.score !== "" && item.score > 0) {
            // 如果score不为空，则检查rule和description是否都有值
            if (item.rule === "" || item.description === "") {
              // 如果rule或description有一个为空，则返回false，表示数据不合法
              return false;
            }
          }
        }
      }
      // 所有数据合法，返回true
      return true;
    },
  },
};
</script>

<style>
.my-button {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  background-color: #1e9fff !important;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
}

.my-button-disabled {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
}

.my-per-button {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  border-color: #16baaa !important;
  color: #16baaa !important;
  background: 0 0;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
}
</style>
