<!--
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-04-10 14:56:13
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-07-10 16:09:59
 * @FilePath: \envision-webe:\sirun\performance-appraisal\src\components\KPIRating.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    id="performance-rating"
    class="div-body active"
  >
    <div class="layui-form">
      <h2>绩效核准 {{ currentMonth }}</h2>
      <form id="performanceRatingForm" class="layui-form">
        <div class="layui-form-item">
          <div v-if="name !== '高丹枫'">
            <label class="layui-form-label" style="width: 120px"
              >选择核准部门:</label
            >
            <div class="layui-input-block">
              <a-select
                placeholder="请选择核准部门"
                @change="getDeptInfo"
                v-model="currentDept"
                allow-clear
                style="width: 60%"
              >
                <a-select-option
                  v-for="item in deptArray"
                  :key="item.deptid"
                  :value="item.deptname"
                  >{{ item.deptname }}</a-select-option
                >
              </a-select>
            </div>
          </div>
        </div>
        <table class="layui-table">
          <colgroup>
            <col width="150" />
            <col width="150" />
            <col />
          </colgroup>

          <thead>
            <tr>
              <th style="width: 20%">考核人</th>
              <th style="width: 20%">考核得分</th>
              <th style="width: 20%">核准得分</th>
              <th style="width: 40%">操作</th>
            </tr>
          </thead>
          <tbody style="max-height: 900px; overflow-y: auto">
            <tr v-for="(item, index) in authorizerArray" :key="index">
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.score }}
              </td>
              <td>
                <a-input-number
                  style="width: 100%"
                  v-model="item.approvalscore"
                  :disabled="isAuthorizer"
                  placeholder="核准得分"
                  :min="0"
                  :max="120"
                />
              </td>
              <td>
                <a-space>
                  <a-button
                    @click="
                      fetchAndFillMyPerformanceTable(
                        item.userid,
                        item.assessment_period
                      )
                    "
                    type="primary"
                    class="my-button"
                  >
                    查看详情
                  </a-button>
                </a-space>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="layui-row">
          <div class="layui-col-sm2 layui-col-md-offset10">
            <a-button
              v-if="isAuthorizer"
              type="primary"
              disabled
              class="my-button-disabled"
            >
              核准
            </a-button>
            <a-popconfirm
              v-else
              title="核准分数过后将无法修改，确认分数无误?"
              ok-text="确定"
              @confirm="confirm()"
              cancel-text="取消"
            >
              <a-button type="primary" class="my-button"> 核准 </a-button>
            </a-popconfirm>
          </div>
        </div>
        <table id="assessorPerformanceTable" class="layui-table">
          <colgroup>
            <col width="150" />
            <col width="150" />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th style="width: 10%">指标类别</th>
              <th style="width: 35%">指标名称</th>
              <th style="width: 35%">计算/评分规则</th>
              <th style="width: 10%">考核分数</th>
              <th style="width: 10%">考核得分</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in KPIRows" :key="index">
              <td>
                {{ row.category }}
              </td>
              <td>
                {{ row.description }}
              </td>
              <td>
                {{ row.rule }}
              </td>
              <td>
                {{ row.examine_score }}
              </td>
              <td>
                {{ row.score }}
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseUrl} from '@/assets/common.js'
// const baseUrl = "https://cim.dingding.sirun.net/";
// const baseUrl = "https://cim-test.dingding.sirun.net/";
const CORP_ID = "ding22edd3ac4f8e7fa8a1320dcb25e91351";
const USER_INFO_URL =
  "https://cim.dingding.sirun.net/get_dingtalk_user_info";
import * as dd from "dingtalk-jsapi";
export default {
  props: {
    globalAccessToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //是否已经核准过
      baseUrl,
      isAuthorizer: false,
      deptArray: [],
      currentDept: "",
      authorizerArray: [],
      KPIRows: [],
      //当前考核月
      name: "",
      userid: "",
      currentMonth: "",
    };
  },
  watch: {
    globalAccessToken: {
      handler(newVal) {
        if (newVal) {
          this.init();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.globalAccessToken !== "") {
      this.init();
    }
  },
  methods: {
    //手动重置页面
    resetPage() {
      this.authorizerArray = [];
      this.isAuthorizer = false;
    },
    async init() {
      this.getMonth();
      dd.getAuthCode({
        corpId: CORP_ID,
        success: async (res) => {
          await this.getName(res.code);
        },
        fail: (err) => {
          console.error("getAuthCode 失败:", err);
        },
        complete: () => {},
      });
    },
    async getName(code) {
      const url = `${USER_INFO_URL}?access_token=${this.globalAccessToken}&user_code=${code}`;
      try {
        const response = await axios.get(url);
        const data = response.data;
        if (response.status !== 200) {
          throw new Error(
            `获取免登录用户数据失败: ${data.message || response.status}`
          );
        }
        const { name, userid } = data.result;
        this.userid = userid;
        this.name = name;
        if (name === "高丹枫") {
          this.getApprovalList(name);
        } else {
          this.getApprovalList2(name);
        }
        console.log("name", name);
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    //获取月份
    getMonth() {
      const currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth();

      // 如果当前日期在1号之前，则仍然算作上一个月
      if (currentDate.getDate() <= 3) {
        // 如果当前月是1月，则需要回到上一年的12月
        if (month === 0) {
          month = 11; // 12月
          year--; // 年份减1
        } else {
          month--; // 月份减1
        }
      }

      const formattedMonth =
        month + 1 < 10 ? "0" + (month + 1) : "" + (month + 1);
      const formattedNextMonth = `${year}年${formattedMonth}月`;
      this.currentMonth = formattedNextMonth;
    },
    async fetchAndFillMyPerformanceTable(userid, assessmentPeriod) {
      try {
        const url =
          this.baseUrl +
          `get_performance_status_data?userid=${userid}&assessment_period=${assessmentPeriod}`;
        const response = await axios.get(url);
        if (response.status !== 200) {
          throw new Error("网络异常");
        }
        this.KPIRows = response.data;
      } catch (error) {
        console.error("Error:", error);
      }
    },
    //获取核准名单
    async getApprovalList(name) {
      console.log("name", name);
      //如果是高丹枫
      const url = this.baseUrl + `users_by_approvaluser/${name}`;
      const response = await axios.get(url);
      if (response.status !== 200) {
        throw new Error("网络异常!");
      }
      const data = response.data;
      if (!data.message) {
        this.authorizerArray = data;
        // 检查是否已经填写核准得分
        const incompleteItems = this.authorizerArray.filter(
          (obj) => !obj.approvalscore
        );
        this.isAuthorizer = incompleteItems.length === 0;
        this.authorizerArray.forEach((item) => {
          // 检查是否有score且没有approvalscore
          if (item.score !== null && item.approvalscore === null) {
            // 将score赋值给approvalscore
            item.approvalscore = item.score;
          }
        });
      } else {
        this.authorizerArray = [];
      }
      console.log("getApprovalList_data", data);
    },
    //获取核准名单(非高)
    async getApprovalList2(name) {
      console.log("name", name);
      //如果是高丹枫
      const url = this.baseUrl + `dept_by_assessor/${name}`;
      const response = await axios.get(url);
      if (response.status !== 200) {
        throw new Error("网络异常!");
      }
      const data = response.data;
      console.log("getApprovalList2->", data);
      if (!data.message) {
        this.deptArray = data;
      } else {
        this.deptArray = [];
      }
    },
    //通过部门获取人员信息
    async getDeptInfo() {
      if (this.currentDept) {
        const url = this.baseUrl + `performance_by_deptname/${this.currentDept}?name=${this.name}`;
        const response = await axios.get(url);
        if (response.status !== 200) {
          throw new Error("网络异常!");
        }
        const data = response.data;
        console.log("getDeptInfo->", data);
        if (!data.message) {
          this.authorizerArray = data;
          // 检查是否已经填写核准得分
          const incompleteItems = this.authorizerArray.filter(
            (obj) => !obj.approvalscore
          );
          this.isAuthorizer = incompleteItems.length === 0;
          this.authorizerArray.forEach((item) => {
            // 检查是否有score且没有approvalscore
            if (item.score !== null && item.approvalscore === null) {
              // 将score赋值给approvalscore
              item.approvalscore = item.score;
            }
          });
        } else {
          this.authorizerArray = [];
        }
      }
    },
    confirm() {
      if (this.authorizerArray.length === 0) {
        this.$message.error("暂无人员信息，请选择部门获取", 2);
        return;
      }
      // 检查 authorizerArray 中是否存在未填写核准得分的对象
      const incompleteItems = this.authorizerArray.filter(
        (obj) => !obj.approvalscore
      );

      // 如果存在未填写核准得分的对象，则显示错误消息并阻止提交
      if (incompleteItems.length > 0) {
        this.$message.error("请填写所有核准得分", 2);
        return;
      }

      // 所有对象都已填写核准得分，可以提交
      axios
        .post(this.baseUrl + "save_approval_score_data", this.authorizerArray)
        .then(() => {
          this.$message.success("核校得分成功！", 2);
          this.resetPage();
        })
        .catch((error) => {
          console.error("核校得分失败:", error);
        });
    },
  },
};
</script>

<style>
.div-body {
  padding-top: 80px; padding-left: 20px; padding-right: 20px
}
.my-button {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  background-color: #1e9fff !important;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
}
.my-button-disabled {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
}
.my-per-button {
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 18px;
  border-color: #16baaa !important;
  color: #16baaa !important;
  background: 0 0;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
}
</style>
