/*
 * @Author: chenshisen 1740602038@qq.com
 * @Date: 2024-04-16 14:03:15
 * @LastEditors: chenshisen 1740602038@qq.com
 * @LastEditTime: 2024-04-16 14:18:39
 * @FilePath: \envision-webe:\sirun\performance\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
