var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-body active",attrs:{"id":"performance-rating"}},[_c('div',{staticClass:"layui-form"},[_c('h2',[_vm._v("绩效核准 "+_vm._s(_vm.currentMonth))]),_c('form',{staticClass:"layui-form",attrs:{"id":"performanceRatingForm"}},[_c('div',{staticClass:"layui-form-item"},[(_vm.name !== '高丹枫')?_c('div',[_c('label',{staticClass:"layui-form-label",staticStyle:{"width":"120px"}},[_vm._v("选择核准部门:")]),_c('div',{staticClass:"layui-input-block"},[_c('a-select',{staticStyle:{"width":"60%"},attrs:{"placeholder":"请选择核准部门","allow-clear":""},on:{"change":_vm.getDeptInfo},model:{value:(_vm.currentDept),callback:function ($$v) {_vm.currentDept=$$v},expression:"currentDept"}},_vm._l((_vm.deptArray),function(item){return _c('a-select-option',{key:item.deptid,attrs:{"value":item.deptname}},[_vm._v(_vm._s(item.deptname))])}),1)],1)]):_vm._e()]),_c('table',{staticClass:"layui-table"},[_vm._m(0),_vm._m(1),_c('tbody',{staticStyle:{"max-height":"900px","overflow-y":"auto"}},_vm._l((_vm.authorizerArray),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.score)+" ")]),_c('td',[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.isAuthorizer,"placeholder":"核准得分","min":0,"max":120},model:{value:(item.approvalscore),callback:function ($$v) {_vm.$set(item, "approvalscore", $$v)},expression:"item.approvalscore"}})],1),_c('td',[_c('a-space',[_c('a-button',{staticClass:"my-button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.fetchAndFillMyPerformanceTable(
                      item.userid,
                      item.assessment_period
                    )}}},[_vm._v(" 查看详情 ")])],1)],1)])}),0)]),_c('div',{staticClass:"layui-row"},[_c('div',{staticClass:"layui-col-sm2 layui-col-md-offset10"},[(_vm.isAuthorizer)?_c('a-button',{staticClass:"my-button-disabled",attrs:{"type":"primary","disabled":""}},[_vm._v(" 核准 ")]):_c('a-popconfirm',{attrs:{"title":"核准分数过后将无法修改，确认分数无误?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.confirm()}}},[_c('a-button',{staticClass:"my-button",attrs:{"type":"primary"}},[_vm._v(" 核准 ")])],1)],1)]),_c('table',{staticClass:"layui-table",attrs:{"id":"assessorPerformanceTable"}},[_vm._m(2),_vm._m(3),_c('tbody',_vm._l((_vm.KPIRows),function(row,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(row.category)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.description)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.rule)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.examine_score)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.score)+" ")])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"150"}}),_c('col',{attrs:{"width":"150"}}),_c('col')])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"20%"}},[_vm._v("考核人")]),_c('th',{staticStyle:{"width":"20%"}},[_vm._v("考核得分")]),_c('th',{staticStyle:{"width":"20%"}},[_vm._v("核准得分")]),_c('th',{staticStyle:{"width":"40%"}},[_vm._v("操作")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"150"}}),_c('col',{attrs:{"width":"150"}}),_c('col')])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"10%"}},[_vm._v("指标类别")]),_c('th',{staticStyle:{"width":"35%"}},[_vm._v("指标名称")]),_c('th',{staticStyle:{"width":"35%"}},[_vm._v("计算/评分规则")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("考核分数")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("考核得分")])])])
}]

export { render, staticRenderFns }