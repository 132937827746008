<template>
  <div id="assessment" class="div-body active">
    <div class="body">
      <h2>绩效考核表</h2>
      <table class="layui-table">
        <tr>
          <td class="bold fixed-width">部门：{{ deptInfo.deptname }}</td>
          <td class="bold large">姓名：{{ perInfo.name }}</td>
          <td class="bold">考核人（直接上级）：{{ deptInfo.assessor }}</td>
          <td class="bold fixed-width">考核周期：{{ formattedNextMonth }}</td>
          <td style="text-align: right">
            <button
              v-if="isSubmit"
              class="layui-btn layui-btn-disabled"
              :disabled="true"
            >
              添加绩效指标
            </button>
            <button v-else @click="addRow" class="layui-btn layui-btn-blue">
              添加绩效指标
            </button>
          </td>
        </tr>
      </table>
      <hr class="layui-border-blue" />

      <div id="performanceTableShowPanle">
        <table id="performanceTable" class="layui-table">
          <thead>
            <tr>
              <th>指标类别</th>
              <th>指标名称</th>
              <th>计算/评分规则</th>
              <th>考核分数</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td style="max-width: 160px">
                <a-select
                  :disabled="isSubmit"
                  @change="changeSave"
                  v-model="row.category"
                  placeholder="指标类别"
                  style="width: 100%"
                >
                  <a-select-option value="业绩类">业绩类</a-select-option>
                  <a-select-option value="行为指标">行为指标</a-select-option>
                </a-select>
              </td>
              <td>
                <a-textarea
                  :disabled="isSubmit"
                  @change="changeSave"
                  v-model="row.description"
                  :rows="4"
                />
              </td>
              <td>
                <a-textarea :disabled="isSubmit" v-model="row.rule" :rows="4" />
              </td>
              <td>
                <a-input-number
                  style="width: 100%"
                  :disabled="isSubmit"
                  @change="changeSave"
                  v-model="row.examine_score"
                  placeholder="考核分数"
                  :min="0"
                  :max="100"
                />
              </td>
              <td>
                <div class="button-group">
                  <div class="buttons-vertical">
                    <a-button
                      :disabled="isSubmit"
                      type="link"
                      icon="caret-up"
                      @click="moveRow(index, 'up')"
                    />
                    <a-button
                      :disabled="isSubmit"
                      type="link"
                      icon="caret-down"
                      @click="moveRow(index, 'down')"
                    />
                  </div>
                  <a-divider type="vertical" />
                  <a-button
                    :disabled="isSubmit"
                    type="link"
                    @click="deleteRow(index, row.id)"
                  >
                    删除
                  </a-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <blockquote id="guide" class="layui-elem-quote">
        <h3>绩效考核指南</h3>
        <p>1、常规项总分100分，加分项独立于常规指标的100分之外计算。</p>
        <p>
          2、考核指标由考核人（直属领导）制定，考核指标必须量化，应不低于80%的量化指标（量化的含义：可计算、可衡量、可考核）。
        </p>
        <p>
          3、每个岗位的月度考核指标5-8个，以体现岗位的重点工作要求，不要求面面俱到。
        </p>
        <p>4、绩效工资计算公式：绩效工资金额=绩效工资*绩效考核系数。</p>
        <p>
          5、绩效考核系数规定：绩效评分在60分及以上，绩效系数为绩效评分/100，绩效系数上限为1.2；
          绩效评分在60分以下，绩效系数为0。
        </p>
        <p id="guide_alert" style="color: red; font-size: 18px">
          *
          在提交绩效评估前，请确保先进行保存操作。一旦提交，将无法对绩效评估进行更改。保存功能允许您在提交前临时保留所做的修改。提交后，保存和提交按钮将被禁用，无法再进行操作。
        </p>
      </blockquote>
      <hr class="layui-border-green" />
      <div id="signature" class="layui-row">
        <div class="layui-col-xs6">
          <div class="layui-form-item">
            <div class="layui-input-group">
              <div class="layui-input-split layui-input-prefix">考核日期</div>
              <input
                v-model="formattedDate"
                type="text"
                id="beikaoheriqi"
                placeholder="请输入考核日期"
                class="layui-input"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
      <div class="layui-row">
        <div class="layui-col-sm2 layui-col-md-offset10">
          <a-space>
            <button
              class="layui-btn layui-btn-primary layui-border-green"
              @click="saveRow"
            >
              保存
            </button>
            <button @click="submitRows" class="layui-btn layui-bg-blue">
              提交
            </button>
          </a-space>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseUrl} from '@/assets/common.js'
// const baseUrl = "https://cim.dingding.sirun.net/";
// const baseUrl = "https://cim-test.dingding.sirun.net/";
const USER_INFO_URL =
  "https://cim.dingding.sirun.net/get_dingtalk_user_info";
const CORP_ID = "ding22edd3ac4f8e7fa8a1320dcb25e91351";
import * as dd from "dingtalk-jsapi";
export default {
  props: {
    globalAccessToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      baseUrl,
      //是否已经保存过
      isSave: false,
      //是否已经提交
      isSubmit: false,
      //个人信息
      perInfo: {
        userid: "",
        name: "",
      },
      //部门信息
      deptInfo: {
        assessor: "",
        deptid: "",
        deptname: "",
      },
      formattedNextMonth: "",
      formattedDate: "",
      rows: [],
    };
  },
  watch: {
    globalAccessToken: {
      handler(newVal) {
        if (newVal) {
          this.init();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getNextMonth();
    this.getKPIMonth();
    if (this.globalAccessToken !== "") {
      this.init();
    }
  },
  methods: {
    moveRow(index, direction) {
      if (direction === "up" && index > 0) {
        this.rows.splice(index - 1, 0, this.rows.splice(index, 1)[0]);
      } else if (direction === "down" && index < this.rows.length - 1) {
        this.rows.splice(index + 1, 0, this.rows.splice(index, 1)[0]);
      }
    },
    async init() {
      dd.getAuthCode({
        corpId: CORP_ID,
        success: async (res) => {
          await this.getUserInfo(res.code);
        },
        fail: (err) => {
          console.error("getAuthCode 失败:", err);
        },
        complete: () => {},
      });
    },
    //修改保存状态
    changeSave() {
      this.isSave = false;
    },
    addRow() {
      this.isSave = false;
      this.rows.push({
        id: "0",
        userid: this.perInfo.userid,
        category: "", // 初始化为默认值
        description: "",
        rule: "",
        examine_score: null,
        assessment_period: this.formattedNextMonth,
      });
    },
    async getUserInfo(code) {
      const url = `${USER_INFO_URL}?access_token=${this.globalAccessToken}&user_code=${code}`;
      try {
        const response = await axios.get(url);
        console.info("getUserInfo-response", response);
        const data = await response.data;
        console.info("datanew", data);
        if (response.status !== 200) {
          throw new Error(
            `获取免登录用户数据失败: ${data.message || response.status}`
          );
        }
        const { name, userid } = data.result;
        this.perInfo = {
          userid,
          name,
        };
        this.getMyperformanceStatus(userid, this.formattedNextMonth);
        this.fetchAndFillTable(userid, this.formattedNextMonth);
        this.getUserInfoByid(userid);
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    fetchAndFillTable(userid, assessmentPeriod) {
      const url =
        this.baseUrl +
        `get_status_data?userid=${userid}&assessment_period=${assessmentPeriod}`;

      axios
        .get(url)
        .then((res) => {
          console.log("fetchAndFillTable-res", res);
          const data = res.data.data;
          let newData = [];
          if (data) {
            this.isSave = true;
            data.forEach((item) => {
              let newItem = {
                ...item,
                userid: this.perInfo.userid,
                assessment_period: this.formattedNextMonth,
              }; // 复制原始对象
              newItem.examine_score = parseFloat(item.examine_score); // 将examine_score转换为数字
              newData.push(newItem);
            });
          }
          this.rows = newData;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    saveRow() {
      if (this.isSubmit) {
        this.$message.info(
          "您的本月的绩效考核信息已经提交，请在[我的绩效]中查看",
          2
        );
        return;
      }
      const emptyCategoryIndex = this.rows.findIndex((row) => !row.category);
      if (emptyCategoryIndex !== -1) {
        this.$message.error("请选择指标类别", 2);
        return;
      }

      // 校验分数之和是否等于100
      const totalScore = this.rows.reduce(
        (acc, row) => acc + (row.examine_score || 0),
        0
      );
      if (totalScore !== 100) {
        this.$message.error("考核分数之和应等于100,请重新填写", 2);
        return;
      }
      axios
        .post(this.baseUrl + "save_my_performance_data", this.rows, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("数据保存成功:", response.data);
        })
        .catch((error) => {
          this.$message.error("数据保存失败:", error);
          return;
        });

      const statusData = {
        name: this.perInfo.name,
        department: this.deptInfo.deptname,
        userid: this.perInfo.userid,
        assessor: this.deptInfo.assessor,
        assessment_period: this.formattedNextMonth,
        signature_date: this.formattedDate,
      };
      // 第二个 POST 请求
      axios
        .post(
          this.baseUrl + "save_my_performance_status",
          JSON.stringify(statusData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.$message.success("绩效信息保存成功！", 2);
          setTimeout(function () {
            document.location.reload();
          }, 1000);
        })
        .catch((error) => {
          this.$message.error("数据保存失败:", error);
          return;
        });
    },
    submitRows() {
      const that = this;
      if (this.isSubmit) {
        this.$message.info(
          "您的本月的绩效考核信息已经提交，请在[我的绩效]中查看",
          2
        );
      } else if (this.isSave) {
        this.$confirm({
          title: "确认真的提交" + this.formattedNextMonth + "绩效考核表么?",
          content: () => (
            <div style="color:red;">确认提交过后不可再进行修改</div>
          ),
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.submitInfo();
          },
          onCancel() {
            console.log("Cancel");
          },
          class: "test",
        });
      } else {
        this.$message.info("请先保存过后再提交绩效考核表", 2);
      }
    },
    submitInfo() {
      this.rows.push({
        id: 0,
        userid: this.perInfo.userid,
        category: "加分项",
        description: "",
        rule: "",
        examine_score: "20",
        assessment_period: this.formattedNextMonth,
      });
      axios
        .post(this.baseUrl + "save_performance_data", this.rows, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {
          console.error("数据保存失败:", error);
        });
      const statusData = {
        name: this.perInfo.name,
        department: this.deptInfo.deptname,
        userid: this.perInfo.userid,
        assessor: this.deptInfo.assessor,
        assessment_period: this.formattedNextMonth,
        signature_date: this.formattedDate,
      };
      // 保存状态数据
      axios
        .post(this.baseUrl + "save_performance_status", JSON.stringify(statusData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.$message.success("绩效信息提交成功！", 2);
          setTimeout(function () {
            document.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.error("数据保存失败:", error);
        });
    },
    deleteRow(index, id) {
      console.log("row.id--->", id);
      this.changeSave();
      if (id !== "0") {
        this.deleteInfo(id);
      }
      this.rows.splice(index, 1);
    },
    deleteInfo(id) {
      axios
        .post(this.baseUrl + "del_my_performance_data", { id: id })
        .then((response) => {
          this.$message.success("删除成功", 2);
          console.log("删除成功:", response.data);
        })
        .catch((error) => {
          this.$message.error("删除成功", error);
        });
    },
    async getUserInfoByid(userid) {
      try {
        const response = await axios.get(this.baseUrl + `user_info_by_id/${userid}`);
        if (response.status !== 200) {
          throw new Error("网络异常");
        }
        console.log("getUserInfoByid-response", response);
        const data = response.data;
        const { deptname, assessor, deptid } = data;
        this.deptInfo = {
          deptid,
          assessor,
          deptname,
        };
        console.log("getUserInfoByid-data", data);
        console.log("this.deptInfo", this.deptInfo);
      } catch (error) {
        console.error("未匹配到数据1:", error);
      }
    },
    async getMyperformanceStatus(userid, assessment_period) {
      try {
        console.log(userid, assessment_period);
        this.isSave = true;
        const response = await axios.get(
          this.baseUrl +
            `get_myperformance_status?userid=${userid}&assessment_period=${assessment_period}`
        );
        console.log("getMyperformanceStatus-response", response);
        if (response.status !== 200) {
          return;
        }
        const data = response.data;
        //如果已经提交
        if (data.issubmit === 1) {
          this.$message.info(
            "您的本月的绩效考核信息已经提交，请在[我的绩效]中查看"
          );
          this.isSubmit = true;
        } else {
          //还没提交
          this.isSave = false;
          this.$message.error(
            "您还没有提交" + assessment_period + "份绩效考核表,请按时提交",
            2
          );
        }
      } catch (error) {
        console.error("未匹配到数据2:", error);
      }
    },
    //获取考核日期
    getKPIMonth() {
      let today = new Date();

      // 获取年、月、日
      let year = today.getFullYear(); // 获取年份
      let month = today.getMonth() + 1; // 获取月份，需要加1因为 getMonth() 返回的是 0-11
      let day = today.getDate(); // 获取日

      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      let formattedDate = year + "-" + month + "-" + day;
      this.formattedDate = formattedDate;
    },
    getNextMonth() {
      const currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth();
      if (currentDate.getDate() > 3) {
        month += 1;
      }
      if (month > 11) {
        month = 0;
        year += 1;
      }
      const formattedMonth =
        month + 1 < 10 ? "0" + (month + 1) : "" + (month + 1);

      const formattedNextMonth = `${year}年${formattedMonth}月`;
      this.formattedNextMonth = formattedNextMonth;
    },
  },
};
</script>
<style scoped>
.button-group {
  display: flex;
  align-items: center;
}

.buttons-vertical {
  display: flex;
  flex-direction: column;
  margin-right: 10px; /* 可以根据需要调整间距 */
}
</style>

<style></style>
