<template>
  <div
    id="my-performance"
    class="div-body active"
  >
    <div class="layui-layout layui-layout-admin">
      <h2>我的绩效</h2>
      <a-collapse
        v-model="acitveKey"
        @change="fetchAndFillMyPerformanceTable()"
        accordion
      >
        <template v-for="row in monthRows">
          <a-collapse-panel
            :key="row.assessment_period"
            :header="row.assessment_period"
          >
            <table id="myPerformanceTable" class="layui-table">
              <colgroup>
                <col width="150" />
                <col width="150" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 10%">指标类别</th>
                  <th style="width: 35%">指标名称</th>
                  <th style="width: 35%">计算/评分规则</th>
                  <th style="width: 10%">考核分数</th>
                  <th style="width: 10%">考核得分</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in KPIRows" :key="index">
                  <td>
                    {{ row.category }}
                  </td>
                  <td>
                    {{ row.description }}
                  </td>
                  <td>
                    {{ row.rule }}
                  </td>
                  <td>
                    {{ row.examine_score }}
                  </td>
                  <td>
                    {{ row.score }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" style="text-align: right">考核分:</td>
                  <td>
                    {{ totalScore }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" style="text-align: right">核准评分:</td>
                  <td>
                    {{ approvalscore }}
                  </td>
                </tr>
              </tbody>
            </table>
          </a-collapse-panel>
        </template>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseUrl} from '@/assets/common.js'
// const baseUrl = "https://cim.dingding.sirun.net/";
// const baseUrl = "https://cim-test.dingding.sirun.net/";
const USER_INFO_URL =
  "https://cim.dingding.sirun.net/get_dingtalk_user_info";
const CORP_ID = "ding22edd3ac4f8e7fa8a1320dcb25e91351";
import * as dd from "dingtalk-jsapi";
export default {
  props: {
    globalAccessToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      baseUrl,
      acitveKey: "",
      approvalscore: 0,
      userid: "",
      monthRows: [],
      KPIRows: [],
    };
  },
  watch: {
    globalAccessToken: {
      handler(newVal) {
        if (newVal) {
          this.init();
        }
      },
      deep: true,
    },
  },
  computed: {
    totalScore() {
      return this.KPIRows.reduce((total, row) => total + row.score, 0);
    },
  },
  mounted() {
    if (this.globalAccessToken !== "") {
      this.init();
    }
  },
  methods: {
    async init() {
      dd.getAuthCode({
        corpId: CORP_ID,
        success: async (res) => {
          await this.getUserid(res.code);
        },
        fail: (err) => {
          console.error("getAuthCode 失败:", err);
        },
        complete: () => {},
      });
    },
    async getUserid(code) {
      const url = `${USER_INFO_URL}?access_token=${this.globalAccessToken}&user_code=${code}`;
      try {
        const response = await axios.get(url);
        const data = response.data;
        if (response.status !== 200) {
          throw new Error(
            `获取免登录用户数据失败: ${data.message || response.status}`
          );
        }
        const { userid } = data.result;
        this.userid = userid;
        await this.fetchData(userid);
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    async fetchData(userid) {
      try {
        const response = await axios.get(
          this.baseUrl + `performance_status/${userid}`
        );
        if (response.status !== 200) {
          throw new Error("网络异常3");
        }
        this.monthRows = response.data;
      } catch (error) {
        console.error("未能获取数据:", error);
      }
    },
    async fetchAndFillMyPerformanceTable() {
      const userid = this.userid;
      const assessmentPeriod = this.acitveKey;
      // console.log("this.acitveKey", this.acitveKey);
      const performanceEntry = this.monthRows.find(
        (row) => row.assessment_period === assessmentPeriod
      );
      this.approvalscore = performanceEntry.approvalscore
      try {
        const url =
          this.baseUrl +
          `get_performance_status_data?userid=${userid}&assessment_period=${assessmentPeriod}`;
        const response = await axios.get(url);
        if (response.status !== 200) {
          throw new Error("网络异常");
        }
        this.KPIRows = response.data;
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>

<style scoped>
.div-body {
  padding-top: 80px; padding-left: 20px; padding-right: 20px
}
</style>
